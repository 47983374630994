import { Controller } from "@hotwired/stimulus"
import { get, patch } from "@rails/request.js"
import { DirectUpload } from "@rails/activestorage"
import PSPDFKit from "pspdfkit"

const LEAVING_PAGE_MESSAGE = "You have unsaved changes to this form, if you leave now those changes will be lost. You must press the 'Save PDF & Continue' button in order to save your changes. Are you sure you want to leave this page?"

const LICENSE_KEY = document.querySelector("meta[name=\"nutrient-license-key\"]").getAttribute("content")

export default class extends Controller {
  static targets = ["editor", "completionForm"]
  static values = {
    pdfTemplateUrl: String,
    fillablePdfId: String,
    fillablePdfFormUrl: String,
    fillablePdfAttachmentsFormUrl: String,
    fillablePdfFieldsFormUrl: String,
    instantJsonUrl: String,
    directUploadUrl: String,
    author: String,
  }

  connect() {
    // We need to inform PSPDFKit where to look for its library assets,
    // i.e.the location of the `pspdfkit-lib` directory.
    const baseUrl = `${window.location.protocol}//${window.location.host}/pspdfkit/`

    this.getInstantJSON().then(instantJSON => {
      PSPDFKit.load({
        baseUrl,
        licenseKey: LICENSE_KEY,
        container: this.editorTarget,
        document: this.pdfTemplateUrlValue,
        instantJSON: instantJSON,
        setAnnotationCreatorName: this.getAuthorValue,
      })
        .then(instance => {
          // Make the instance available to the entire controller
          this.editorInstance = instance

          // this.provisionMagicFields()
          this.registerFormFieldListners()
          this.registerAnnotationListeners()
        })
        .catch(error => {
          alert(`Error loading Smart Form Editor\n${error.message}`)
        })
    })
      .catch(error => {
        alert(`Error prepopulating form data\n${error.message}`)
      })
  }

  registerFormFieldListners() {
    this.editorInstance.addEventListener("formFieldValues.update", updatedFormFieldValues => {
      const formFieldValues = updatedFormFieldValues.toJS()

      formFieldValues.forEach(formField => {
        this.savePdfFieldValue(formField.name, formField.value)
      })
    })
  }

  registerAnnotationListeners() {
    const events = ["annotations.create", "annotations.update", "annotations.delete"]

    events.forEach(event => {
      this.editorInstance.addEventListener(event, _annotations => {
        this.exportInstantJSON()
      })
    })
  }

  // provisionMagicFields() {
  //   this.editorInstance.getFormFields().then(formFields => {
  //     const magicFieldObjects = formFields.toJS().filter(formField => formField.name.includes("x_pass_"))
  //     const magicFields = magicFieldObjects.map(magicField => ({ name: magicField.name, value: magicField.value }))
  //   })
  // }

  async exportInstantJSON() {
    await this.editorInstance.exportInstantJSON().then(instantJSON => {
      this.saveInstantJSON(instantJSON)
    })
  }

  async saveInstantJSON(instantJSON) {
    this.showPageLoader()

    const response = await patch(this.fillablePdfFormUrlValue, {
      body: JSON.stringify({ fillable_pdf: { metadata: { instantJSON: instantJSON } } }),
      contentType: "application/json",
      responseKind: "json"
    })

    this.hidePageLoader()

    if (!response.ok) {
      const json = await response.json
      alert(`Error Saving PDF Field\n${json.message}`)
    }
  }

  async getInstantJSON() {
    const response = await get(this.fillablePdfFormUrlValue, {
      contentType: "application/json",
      responseKind: "json"
    })

    const json = await response.json

    if (response.ok) {
      const instantJSON = json.instantJSON
      return instantJSON
    } else {
      alert(`Error Fetching Instant JSON \n${json.message}`)
    }
  }

  async savePdfFieldValue(fieldName, fieldValue) {
    const response = await patch(this.fillablePdfFieldsFormUrlValue, {
      body: JSON.stringify({ fillable_pdf_field: { name: fieldName, value: fieldValue } }),
      contentType: "application/json",
      responseKind: "json"
    })

    if (!response.ok) {
      const json = await response.json
      alert(`Error Saving PDF Field\n${json.message}`)
    } else {
      this.completionFormTarget.dataset.changed = "true"
    }
  }

  submit(event) {
    event.preventDefault()

    const completionForm = this.completionFormTarget

    this.exportPdf()
      .then(() => {
        this.showPageLoader()
        completionForm.requestSubmit()
      })
      .catch(error => {
        alert(`Error submitting smart form\n${error.message}`)
      })
  }

  async exportPdf() {
    this.editorInstance.exportPDF({ flatten: true }).then(pdf => {
      const blob = new File([pdf], `smart-form-${this.fillablePdfIdValue}-${Date.now()}.pdf`, { type: "application/pdf" })
      const upload = new DirectUpload(blob, this.directUploadUrlValue)

      return new Promise((resolve, reject) => {
        upload.create((error, blob) => {
          if (error) {
            reject(`ActiveStorage Error: ${error}`)
          } else {
            return patch(this.fillablePdfFormUrlValue, {
              body: JSON.stringify({ fillable_pdf: { filled_pdf: blob.signed_id } }),
              contentType: "application/json",
              responseKind: "json"
            })
              .then(() => {
                resolve("Done saving PDF via ActiveStorage direct_upload")
              })
              .catch(error => {
                alert(`Error Saving PDF Annotations\n${error}`)
              })
          }
        })
      })
    })
  }

  allowFormSubmission() {
    this.completionFormTarget.dataset.changed = "false"
  }

  hasPdfChanged() {
    return false //this.completionFormTarget.dataset.changed == "true"
  }

  leavingPage(event) {
    if (this.hasPdfChanged()) {
      if (event.type == "turbo:before-visit") {
        if (!window.confirm(LEAVING_PAGE_MESSAGE)) {
          event.preventDefault()
        }
      } else {
        event.returnValue = LEAVING_PAGE_MESSAGE
        return event.returnValue
      }
    }
  }

  showPageLoader() {
    document.getElementById("loading-indicator").style.display = ""
  }

  hidePageLoader() {
    document.getElementById("loading-indicator").style.display = "none"
  }
}

