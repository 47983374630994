import { Controller } from "@hotwired/stimulus"
import Modal from "bootstrap/js/dist/modal"

export default class extends Controller {
  static values = {
    pdfUrl: String,
    title: String
  }

  show(event) {
    event.preventDefault()

    const titleTarget = document.getElementById("pdf-modal-title")
    const buttonTarget = document.getElementById("pdf-modal-download-link")
    const title = this.titleValue.replace(/[^a-z0-9áéíóúñü \.,_-]/gim, "")

    titleTarget.innerHTML = title
    buttonTarget.href = this.pdfUrlValue

    if (navigator.userAgent.toLowerCase().indexOf("firefox") > -1) {
      const placholder = document.getElementById("pdf-modal-placeholder")
      this.renderEmbed(placholder)
    } else {
      this.renderIframe()

      const iFrame = document.getElementById("pdf-modal-iframe")
      const frameDoc = iFrame.contentDocument || iFrame.contentWindow.document
      this.renderEmbed(frameDoc.body)
    }


    const modalEl = document.getElementById("pdf-modal")
    this.modal = new Modal(modalEl, {
      keyboard: true,
    })

    this.modal.show()
  }

  renderIframe() {
    const placholder = document.getElementById("pdf-modal-placeholder")

    const iFrame = document.createElement("iframe")
    iFrame.width = "100%"
    iFrame.height = "600"
    iFrame.frameBorder = 0
    iFrame.id = "pdf-modal-iframe"
    iFrame.scrolling = "no"

    placholder.appendChild(iFrame)
  }

  clearIframe() {
    const placholder = document.getElementById("pdf-modal-placeholder")
    placholder.innerHTML = ""
  }

  renderEmbed(element) {
    const pdfEmbed = document.createElement("embed")
    pdfEmbed.src = this.pdfUrlValue
    pdfEmbed.type = "application/pdf"
    pdfEmbed.width = "100%"
    pdfEmbed.height = "600"

    element.appendChild(pdfEmbed)
  }

  hide() {
    // this hack is needed because Bootstrap's remove() method deletes the modal template
    // from the DOM, and for now this modal requires that it stays there
    // consider refactoring this at somepoint

    const modalEl = document.getElementById("pdf-modal")
    const modalBackground = document.querySelector(".modal-backdrop")
    const body = document.querySelector("body")
    const pdfObject = document.getElementById("pdf-object")

    this.clearIframe()

    body.classList.remove("modal-open")
    body.style = ""

    modalBackground.remove()
    modalEl.style.display = "none"
  }
}
